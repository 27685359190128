@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $cherry;
  z-index: 20;
  font-weight: 300;

  
  button {
    border: none;
    background: transparent;
    margin: 20px;
    cursor: pointer;
    
    svg {
      color: $white;
      width: 30px;
      height: 100%;

      &:hover {
        color: $pink;
      }
    }
  }

  ul {
    padding: 0;
    align-items: center;

    li {
      padding: clamp(15px, 2vw, 20px);
      margin: clamp(10px, 1vw, 10px);
      list-style: none;
    }


    li * {
      color: $white;
      font-size: clamp(1rem, 2vw, 2rem);
      letter-spacing: 2px;
      padding: 20px;

      &:hover {
        color: $pink;
      }
    }
  }
}