@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

.contact-page {
  p {
    font-size: clamp(1rem, 1.25vw, 1.5rem);
    line-height: 200%;
  }
}
.contact-info {
  border-radius: 20px;
  background-color: $white;
  padding: clamp(50px, 5vw, 100px) clamp(30px, 5vw, 75px);
  flex-grow: 1;

  section {
    display: flex;
    align-items: center;
  }

  a {
    font-weight: 700;
    color: $cherry;
    margin-right: clamp(15px, 1vw, 20px);

    &.icon-link {
      width: clamp(20px, 2vw, 30px);
      display: flex;
      justify-content: center;
    }

    &:hover {
      color: $pink;
    }
  }

  svg {
    display: flex;
    justify-content: flex-start;
    width: 50px;
    height: 50px;
    text-align: left;
  }
}
