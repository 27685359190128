@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: min(100%, 1300px);
  height: clamp(30px, 2vw, 50px);
  padding-inline: clamp(10px, 10vw, 100px);
  padding-block: clamp(15px, 1vw, 30px);
  margin-bottom: clamp(20px, 2vw, 30px);
  z-index: 5;
  
  
  &.mobile {
    padding-inline: clamp(10px, 3vw, 50px);
  }

  &.cherry-bg {
    background-color: $cherry;
    box-shadow: 0px 10px 10px -10px gray;

    .navlink {
      color: $white;
    }

    button svg {
      color: $white;

      &:hover {
        color: $pink;
      }
    }
  }

  .navlink {
    font-size: clamp(0.75rem, 1vw, 1rem);
    letter-spacing: 1.4px;
    padding-right: clamp(50px, 5vw, 100px);
    color: $cherry;

    &:hover {
      color: $pink;
    }
  }
}