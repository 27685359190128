@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

.footer {
  text-align: center;
  font-size: clamp(1rem, 2vw, 1rem);
  line-height: 200%;
  margin-top: clamp(50px, 6vw, 100px);
  margin-bottom: 5vw;
  padding-inline: clamp(10px, 5vw, 150px);
  
  .copyright {
    font-size: clamp(0.75rem, 1.5vw, 0.75rem);
    font-weight: 700;
  }
}