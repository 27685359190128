@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

html {
  font-size: 16px;
}

body {
  position: relative;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  color: $cherry;
  font-size: 1rem;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  width: min(100%, 1600px);
  margin-inline: auto;
  background: transparent;
  background-image: url('../public/images/background.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  z-index: 1;
}

.app::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 230, 230, 0.80);
  z-index: -1;
}

svg {
  color: $cherry;

  &:hover {
    color: $pink;
  }
}

.text-align-center {
  text-align: center;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.no-underline {
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.menu-icon {
  border: none;
  background: transparent;
  margin-left: clamp(20px, 3vw, 75px);
  cursor: pointer;

  svg {
    color: $cherry;
    width: clamp(20px, 5vw, 50px);
    height: 100%;
  }
}

.page-margin {
  margin: clamp(10px, 10vw, 100px);
  margin-bottom: 0;
}

.page-padding {
  padding-top: clamp(75px, 5vw, 150px);
}

.heading {
  font-size: clamp(1.5rem, 3vw, 4rem);
  color: $pink;
  margin-top: 0;
}

.btn {
  border-radius: clamp(5px, 0.25vw, 10px);
  border-radius: 50px;
  width: 100px;
  font-size: clamp(0.7rem, 1vw, 1rem);
  padding-block: clamp(0.75rem, 1.75vw, 1.5rem);
  padding-inline: clamp(1rem, 2.5vw, 2rem);
  margin-right: clamp(5px, 0.75vw, 15px);
  border: none;
  cursor: pointer;
  color: $white;
  background-color: $cherry;
  border: 1px solid $cherry;

    &:hover {
      background-color: $pink;
    }

  &.secondary-btn {
    color: $cherry;
    border: 1px solid $cherry;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $pink;
    }
  }
}

.back-to-top-btn {
  position: fixed;
  right: clamp(30px, 5vw, 75px);
  bottom: clamp(30px, 5vw, 75px);
  color: $cherry;
  border: none;
  border-radius: 50%;
  background: $dark-gray;
  width: 50px;
  height: 50px;
  
  svg {
    color: $white;
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: $pink;  
  }
}

@media (max-width: 599px) {
  .app {
    overflow: hidden;
  }

  .page-margin {
    margin: clamp(10px, 5vw, 50px);
    margin-top: clamp(65px, 15vw, 100px);
  }
}

@media (max-width: 340px) {
  .page-margin {
    margin-inline: 5px;
  }
}



