@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Open+Sans:wght@300;400;700;800&display=swap');

$cherry: rgba(58, 9, 11, 1);
$light-cherry: rgba(58, 9, 11, 0.25);
$pink: rgba(231, 113, 127, 1);
$light-pink: rgba(231, 113, 127, 0.25);
$green: rgb(135, 189, 96);
$gray: rgba(230, 230, 230, 1);
$light-gray: rgba(233, 233, 233, 0.5);
$shadow-gray: rgb(150, 150, 150);
$dark-gray: rgb(128, 128, 128);
$white: #FFFFFF;

.phone-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.song {
      font-size: clamp(0.5rem, 1vw, 1rem);
      width: clamp(100px, 15vw, 200px);
      height: 30px;
      text-align: center;
      padding: clamp(5px, 1vw, 20px);
      margin-block: 0 clamp(10px, 1vw, 20px);
      color: $cherry;

      &:hover {
        color: $pink;
      }
    }

.phone {
  background-color: $white;
  border-radius: 20px;
  box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.5);
  z-index: 1;
  align-items: center;

  .phone-display {
    background-color: $pink;
    border-radius: 15px 15px 0 0;
    margin: clamp(5px, 1.25vw, 10px);
    padding-block: clamp(15px, 3vw, 30px);
    padding-inline: clamp(20px, 1vw, 15px);
    align-items: center;
    text-align: center;

    .avatar {
      border-radius: 50%;
      width: clamp(100px, 12vw, 200px);
      height: 100%;
      box-shadow: 0px 0px 5px 0px $cherry;
    }

    .name {
      font-family: 'Satisfy', cursive;
      font-size: clamp(1.5rem, 2.5vw, 2.5rem);
      font-weight: 400;
      color: $white;
      margin-block: clamp(10px, 1vw, 30px) 0;
    }

    .title {
      text-transform: uppercase;
      font-size: clamp(0.65rem, 1vw, 1.25rem);
      font-weight: 400;
      letter-spacing: 0.25px;
      color: $white;
      margin-block: 0;
    }
  }

  .phone-control {
    align-items: center;

    a {
      text-decoration: none;
    }

    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      width: clamp(110px, 15vw, 200px);
      

      &::-webkit-slider-runnable-track {
        background: $cherry;
        height: min(0.5vw, 3px);
        border-radius: 1rem;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none; 
        appearance: none;
        margin-top: max(-0.5vw, -6px); 
        background-color: $pink;
        height: min(1.25vw, 15px);
        width: min(1.25vw, 15px); 
        border-radius: 50%;
      }

      &::-moz-range-track {
        background: $cherry;
        height: min(0.5vw, 3px);
        border-radius: 1rem;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none; 
        appearance: none;
        margin-top: max(-0.5vw, -6px); 
        background-color: $pink;
        height: min(1.25vw, 15px);
        width: min(1.25vw, 15px); 
        border-radius: 50%;
      }

      &::-moz-range-progress {
        background-color: $pink;
      }

      &::-ms-track {
        background-color: $cherry;
      }

      &::-ms-thumb {
        background-color: $pink;
      }

      &::-ms-fill-lower {
        background-color: $pink;
      }
    }

    .control-buttons {
      margin: clamp(10px, 2vw, 25px);

      svg {
        width: clamp(20px, 3vw, 50px);
        height: clamp(20px, 3vw, 50px);
      }

      button {
        background-color: $white;
        border: none;
        padding-inline: clamp(5px, 1vw, 10px);
        cursor: pointer;
      }
    }
  }
}

.home-page {
  position: relative;

  .home {
    width: 100%;
    border-radius: 20px;
    background-color: $white;
    margin-left: clamp(50px, 10vw, 200px);
    padding-block: clamp(50px, 8vw, 150px);
    padding-inline: clamp(135px, 15vw, 175px) clamp(30px, 5vw, 75px);

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-size: clamp(1.5rem, 3vw, 4rem);
      color: $pink;
      margin-top: 0;
    }

    .home-description {
      p {
      font-size: clamp(1rem, 1.25vw, 1.5rem);
      line-height: 200%;
      }
    }

    .home-buttons {
        margin-top: clamp(30px, 4vw, 75px);
    }
  }
}

@media (max-width: 599px) {
  .home-page {
    flex-direction: column;

    .home {
      text-align: center;
      padding-inline: 0;

      h1 {
        font-size: clamp(1.25rem, 6.5vw, 4rem);
      }

      h2 {
        font-size: clamp(0.75rem, 3.5vw, 3rem);
      }

      .home-description p {
        font-size: clamp(1rem, 2vw, 2rem);
        margin-bottom: 10vw;
      }

      .btn {
        font-size: clamp(0.7rem, 2.5vw, 2rem);
        padding-block: 3.5vw;
        padding-inline: 5vw;
      }
    }

    .phone-container {
      position: unset;
      
      .phone {
        border-radius: clamp(20px, 10vw, 50px);
        box-shadow: inset 0px 0px 10px $dark-gray;

        .phone-display {
          border-radius: clamp(20px, 8vw, 50px) clamp(20px, 8vw, 50px) 0 0 ;
          flex-direction: row;
          align-items: center;
          min-height: 100%;
          margin: clamp(10px, 1vw, 30px);
          padding-block: 5vw;
          
          .avatar {
            width: 40%;
            height: 100%;
            margin-right: 5vw;
          }

          .name {
            font-size: clamp(1.5rem, 7vw, 3rem);
          }

          .title {
            font-size: clamp(0.75rem, 3vw, 3rem);
            text-align: center;
          }
        }

        .phone-control {
          margin-top: 10vw;

          .song {
            font-size: clamp(0.75rem, 3vw, 1.5rem);
            width: clamp(200px, 80vw, 350px);
            margin-block: 0 clamp(10px, 5vw, 30px);
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          width: 60vw;
      

          &::-webkit-slider-runnable-track {
            background: $cherry;
            height: 1vw;
            border-radius: 1rem;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none; 
            appearance: none; 
            background-color: $pink;
            margin-top: -1.5vw;
            height: 4vw;
            width: 4vw; 
            border-radius: 50%;
          }

          &::-moz-range-track {
            background: $cherry;
            height: 1vw;
            border-radius: 1rem;
          }

          &::-moz-range-thumb {
            -webkit-appearance: none; 
            appearance: none; 
            background-color: $pink;
            margin-top: -1.5vw;
            height: 4vw;
            width: 4vw; 
            border-radius: 50%;
          }

          &::-moz-range-progress {
            background-color: $pink;
          }

          &::-ms-track {
            background-color: $cherry;
          }

          &::-ms-thumb {
            background-color: $pink;
          }

          &::-ms-fill-lower {
            background-color: $pink;
          }
        }

        .control-buttons {
          margin: 8vw;

          svg {
            width: 7vw;
            height: 100%;
            padding-inline: 2.5vw;
            padding-block: 0;

            &.play {
              width: 10vw;
            }
          }
        }
      }
    }

    .home {
      width: 75%;
      margin-inline: auto;
      padding: 5vw;

      h1 {
        font-size: 5vw;
      }
    }
  }
}

@media (min-width: 800px) {
  .home-page {
    .home {
      padding-block: clamp(50px, 10vw, 150px);
    }
  }
}